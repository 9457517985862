import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Markdown from "react-commonmark"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Avatar from "../components/ui/Avatar"
import Cushion from "../components/ui/Cushion"
import Flex from "../components/ui/Flex"
import MaxWidth from "../components/ui/MaxWidth"
import Overlap from "../components/Overlap"
import Rectangle from "../components/ui/Rectangle"
import Show from "../components/ui/Show"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"

const BookComplete = ({ data, location }) => {
  const { contactDetailsJson } = data;
  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}

    >
    <Helmet>
    <script>
      {`gtag(('event', 'conversion', { send_to: 'AW-858634123/r5WlCPmgqKMYEIvvtpkD'})); `}
    </script>

    </Helmet>
      <SEO title="Thank you for your booking" />
      <ThermalBackground component="div">
        <ConcaveBottom fill="vLightGrey">
          <Cushion
            component="div"
            horizontal="small"
            top="xxlarge"
            bottom="xxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
            <Typography component="div" preset="hero" color="white">
              Thank you for your booking
            </Typography>
            <Cushion top="medium">
              <Typography component="div" preset="body" color="white">
                We will be in contact shortly to confirm your booking.
              </Typography>
            </Cushion>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
    </Frame>
  )
}

BookComplete.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default BookComplete

export const pageQuery = graphql`
  query BookCompleteQuery {
    contactDetailsJson {
      email
      mobile
      landline
    }
  }
`


