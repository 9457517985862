import React from "react"
import PropTypes from "prop-types"
import withTheme from "../Theme/withTheme"

const Avatar = props => {
  const {
    alt,
    image,
    size,
    style: customStyle,
    uiTheme,
    ...otherProps
  } = props

  return (
    <div
      {...otherProps}
      style={{
        position: "relative",
        width: size,
        height: size,
        overflow: "hidden",
        borderRadius: "50%",
        ...customStyle
      }}
    >
      <img
        src={image}
        alt={alt}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: size,
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  )
}

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderStyle: PropTypes.string,
  borderWidth: PropTypes.number,
  boxShadow: PropTypes.string,
  image: PropTypes.string.isRequired,
  preset: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  uiTheme: PropTypes.object,
}

Avatar.defaultProps = {
  backgroundColor: null,
  borderColor: null,
  radius: null,
  borderStyle: null,
  borderWidth: null,
  boxShadow: null,
  preset: "default",
  size: null,
  style: {},
  uiTheme: null,
}

Avatar.displayName = "Avatar"
export default withTheme(Avatar)
